import React, { useCallback, useEffect, useState } from 'react';
import { ColorSchemeName, Platform, View, LogBox } from 'react-native';

import '@expo/match-media';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import * as eva from '@eva-design/eva';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ApplicationProvider, IconRegistry, StyleService } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { CachePersistor } from 'apollo3-cache-persist';
import Constants from 'expo-constants';
import * as SplashScreen from 'expo-splash-screen';
import 'intl';
import 'intl-pluralrules';
import 'intl/locale-data/jsonp/en';
import { DateTime } from 'luxon';
import { initReactI18next } from 'react-i18next';
import ErrorBoundary from 'react-native-error-boundary';
import FlashMessage from 'react-native-flash-message';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import * as Sentry from 'sentry-expo';
import 'react-native-gesture-handler';
import '@expo/match-media';

import { AppIconsPack } from './app-icons-pack';
import { default as mapping } from './app-mapping-eva.json';
import { default as appThemeEvaDark } from './app-theme-eva.dark.json';
import { default as appThemeEvaLight } from './app-theme-eva.light.json';
import { createApolloClientPersistAsync } from '../apollo/create-apollo-client-persist-async';
import { CURRENT_USER_CONTEXT_QUERY } from '../apollo/graphql-queries';
import { AppLoadingComponent } from '../components/app-loading.component';
import AppUpdate from '../components/common/modal/app-update';
import { ConfirmModalProvider } from '../components/common/modal/ConfirmModal';
import ConnectionStatusBar from '../components/connection-status-bar.component';
import { AppContextProvider } from '../contexts/AppContext';
import { AppUserContextProvider } from '../contexts/AppUserContext';
import { ThemeContextProvider } from '../contexts/ThemeContext';
import { GLOBAL_CONSTANTS } from '../globals';
import useCachedResources from '../hooks/useCachedResources';
import useColorScheme from '../hooks/useColorScheme';
import i18n, { i18nInitOptions } from '../i18n/i18n';
import {
  deleteCurrentUserContextFromStorage,
  loadCurrentUserContext,
} from '../modules/authentication/authentication.module';
import { AppNavigator } from '../navigation/app.navigator';
import { AuthenticationService } from '../services/authentication.service';
import { LocalUserContext } from '../types';
import { getIsBackendReachable } from '../utils/get-is-backend-reachable';
import { getGoogleMapsApiKey } from '../utils/google';

if (Platform.OS === 'android') {
  // See https://github.com/expo/expo/issues/6536 for this issue.
  if (typeof (Intl as any).__disableRegExpRestore === 'function') {
    (Intl as any).__disableRegExpRestore();
  }
}

const IGNORED_LOGS = [
  'Text: unsupported configuration.',
  'Require cycle: src',
  'Select: unsupported configuration.',
  'WARN  Text: unsupported configuration.',
  ' WARN  Text: unsupported configuration.',
];
LogBox.ignoreLogs(IGNORED_LOGS);

Sentry.init({
  dsn:
    Constants.expoConfig?.extra?.sentryDSN ||
    'https://ed61475547344f138ffbc675a980c2b5@o910233.ingest.sentry.io/5852998',
  enableInExpoDevelopment: false,
  release: Constants.expoConfig?.version,
  environment: Constants.expoConfig?.extra?.priojetAppEnv || 'production',
  attachStacktrace: true,
  enabled: Constants.expoConfig?.extra?.priojetAppEnv !== 'development',
  initialScope: { tags: { platform: Platform.OS } },
});

i18n.use(initReactI18next).init(i18nInitOptions());

export default function App(): React.ReactElement | null {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [apolloPersistor, setApolloPersistor] = useState<CachePersistor<NormalizedCacheObject>>();
  const [currentUserContext, setCurrentUserContextState] = useState<
    LocalUserContext | null | undefined
  >(undefined);
  const [isScriptReady, setIsScriptReady] = useState(Platform.OS !== 'web');
  const [isLatestData, setIsLatestData] = useState(false);
  const [theme, setTheme] = useState<NonNullable<ColorSchemeName>>();
  const [userContextLastReFetchedAt, setUserContextLastReFetchedAt] = useState<
    DateTime | undefined
  >(undefined);

  const handleSetCurrentUserContextState = (data: LocalUserContext | null | undefined) => {
    setCurrentUserContextState(data);
    setUserContextLastReFetchedAt(DateTime.now());
    if (!isLatestData) {
      setIsLatestData(true);
    }
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      const doc: any = document;
      if (Constants.expoConfig?.extra?.priojetAppEnv !== 'development') {
        const clarityCode = Constants.expoConfig?.extra?.clarityCode;
        const clarityScript = doc.createElement('script');
        clarityScript.type = 'text/javascript';
        clarityScript.async = true;
        clarityScript.innerHTML = `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${clarityCode}");
      `;
        doc.head.appendChild(clarityScript);
      }

      const googleScript = doc.createElement('script');
      googleScript.src =
        'https://maps.googleapis.com/maps/api/js?key=' + getGoogleMapsApiKey('web');
      googleScript.async = true;
      doc.head.appendChild(googleScript);

      setIsScriptReady(true);
    }
  }, []);

  useEffect(() => {
    const setupUserContext = async () => {
      if (!currentUserContext) {
        let currentLocalUserContext = await loadCurrentUserContext();
        if (
          currentLocalUserContext &&
          currentLocalUserContext.device &&
          currentLocalUserContext.deviceId
        ) {
          const isIsBackendReachable = await getIsBackendReachable();
          if (isIsBackendReachable && currentLocalUserContext.tokenResponse) {
            const authenticationService = new AuthenticationService();
            currentLocalUserContext =
              await authenticationService.getLocalUserContextWithRefreshedTokenResponseAsync({
                localUserContext: currentLocalUserContext as LocalUserContext,
              });
            if (!currentLocalUserContext) {
              await deleteCurrentUserContextFromStorage();
              setCurrentUserContextState(null);
              setUserContextLastReFetchedAt(DateTime.now());
              return;
            }
          }
          setCurrentUserContextState(currentLocalUserContext);
          setUserContextLastReFetchedAt(DateTime.now());
        } else {
          setCurrentUserContextState(null);
        }
      }
    };
    setupUserContext();
  }, []);

  useEffect(() => {
    let isActive = true;
    const func = async () => {
      const storedThemeName = await AsyncStorage.getItem(
        GLOBAL_CONSTANTS.ACTIVE_THEME_NAME_STORAGE_KEY,
      );
      if (!storedThemeName || !['dark', 'light'].includes(storedThemeName)) {
        await AsyncStorage.setItem(GLOBAL_CONSTANTS.ACTIVE_THEME_NAME_STORAGE_KEY, colorScheme);
        if (isActive) {
          setTheme(colorScheme);
        }
      }
      if (storedThemeName) {
        if (isActive) {
          setTheme(storedThemeName as NonNullable<ColorSchemeName>); // if this matches dark or light, use the stored one.
        }
      }
    };
    func();
    () => {
      isActive = false;
    };
  }, [colorScheme]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clearApolloCache = useCallback(() => {
    if (!apolloPersistor) {
      return;
    }
    apolloPersistor.purge();
  }, [apolloPersistor]);

  const refetchCurrentUserContext = async (firstAttempt = false) => {
    let newCurrentUserContext;
    if (apolloClient && currentUserContext?.user) {
      try {
        const res = await apolloClient.query({
          query: CURRENT_USER_CONTEXT_QUERY,
          fetchPolicy: 'network-only',
          context: {
            forbidRepeatRequest: true,
          },
        });
        if (res?.data?.currentUserContext) {
          newCurrentUserContext = { ...currentUserContext, ...res.data.currentUserContext };
          handleSetCurrentUserContextState(newCurrentUserContext);
          setUserContextLastReFetchedAt(DateTime.now());
          if (!isLatestData) {
            setIsLatestData(true);
          }
        }
      } catch (e: any) {
        if (
          firstAttempt &&
          e.graphQLErrors?.[0].message?.includes(
            'Authentication failed due to invalid or expired Bearer token',
          )
        ) {
          setTimeout(() => {
            refetchCurrentUserContext();
          }, 1000);
        }
      }
    }
    return newCurrentUserContext;
  };

  useEffect(() => {
    async function init() {
      // THIS IS REQUIRED SO THAT Chromium-based BROWSERS WOULD WORK. OTHERWISE THEY MIGHT HANG.
      const { apolloClient: _apolloClient, apolloPersistor: _apolloPersistor } =
        await createApolloClientPersistAsync();
      setApolloClient(_apolloClient);
      setApolloPersistor(_apolloPersistor);
    }
    init();
  }, []);

  useEffect(() => {
    if (currentUserContext?.user && !isLatestData) {
      refetchCurrentUserContext(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserContext?.user, isLatestData]);

  useEffect(() => {
    if (currentUserContext?.user !== undefined) {
      if (Platform.OS === 'web') {
        Sentry.Browser.setUser({
          id: currentUserContext.sub,
          email: currentUserContext.user?.email,
          username:
            currentUserContext.user?.firstNames && currentUserContext.user?.lastName
              ? `${currentUserContext.user?.firstNames} ${currentUserContext.user?.lastName}`
              : undefined,
        });
      } else {
        Sentry.Native.setUser({
          id: currentUserContext.sub,
          email: currentUserContext.user?.email,
          username:
            currentUserContext.user?.firstNames && currentUserContext.user?.lastName
              ? `${currentUserContext.user?.firstNames} ${currentUserContext.user?.lastName}`
              : undefined,
        });
      }
    }
  }, [currentUserContext]);

  const onLayoutRootView = useCallback(async () => {
    if (isLoadingComplete) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [isLoadingComplete]);

  if (!isLoadingComplete || !isScriptReady || !apolloClient || currentUserContext === undefined) {
    return <></>;
  }
  return (
    <View onLayout={onLayoutRootView} style={styles.flex1}>
      <IconRegistry icons={[EvaIconsPack, AppIconsPack]} />
      <ApolloProvider client={apolloClient}>
        <ActionSheetProvider>
          <AppUserContextProvider
            currentUserContext={currentUserContext}
            lastReFetchedAt={userContextLastReFetchedAt}
            refetchCurrentUserContext={refetchCurrentUserContext}
            setCurrentUserContextState={handleSetCurrentUserContextState}
          >
            <AppContextProvider currentUserContext={currentUserContext}>
              <ThemeContextProvider theme={theme} setTheme={setTheme}>
                <ApplicationProvider
                  {...eva}
                  customMapping={mapping as any}
                  theme={{
                    ...(eva as any)[theme || 'light'],
                    ...(theme === 'light' ? appThemeEvaLight : appThemeEvaDark),
                  }}
                >
                  <SafeAreaProvider
                    style={
                      Platform.OS === 'web'
                        ? styles.globalContainerWeb
                        : styles.globalContainerMobile
                    }
                  >
                    <SafeAreaView
                      style={[
                        styles.flex1,
                        theme === 'light'
                          ? styles.backgroundColorWhite
                          : styles.backgroundColorDark,
                      ]}
                    >
                      <AppUpdate>
                        <ConfirmModalProvider>
                          <>
                            <ConnectionStatusBar />
                            <ErrorBoundary>
                              {currentUserContext?.device === undefined ? (
                                <AppLoadingComponent
                                  setCurrentUserContext={handleSetCurrentUserContextState}
                                />
                              ) : (
                                <AppNavigator />
                              )}
                            </ErrorBoundary>
                          </>
                        </ConfirmModalProvider>
                      </AppUpdate>
                    </SafeAreaView>
                  </SafeAreaProvider>
                  <FlashMessage
                    position="top"
                    duration={3000}
                    style={[
                      styles.flashMessage,
                      Platform.OS === 'android' && styles.flashMessagePaddingTop,
                    ]}
                    titleStyle={styles.flashMessageText}
                    textStyle={styles.flashMessageText}
                  />
                </ApplicationProvider>
              </ThemeContextProvider>
            </AppContextProvider>
          </AppUserContextProvider>
        </ActionSheetProvider>
      </ApolloProvider>
    </View>
  );
}

const styles = StyleService.create({
  flex1: {
    flex: 1,
  },
  backgroundColorWhite: {
    backgroundColor: '#ffffff',
  },
  backgroundColorDark: {
    backgroundColor: 'rgb(34, 43, 69)',
  },
  text: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  spinner: {
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 30,
    backgroundColor: '#ffffff',
    padding: 8,
  },
  globalContainerWeb: {
    // minWidth: 600,
    maxWidth: 1200,
    position: 'relative',
    width: '100%',
    alignSelf: 'center',
  },
  globalContainerMobile: {},
  flashMessage: {
    zIndex: 1001,
    paddingTop: 10,
  },
  flashMessagePaddingTop: {
    paddingTop: Constants.statusBarHeight + 10,
  },
  flashMessageText: {
    maxWidth: 1200,
    alignSelf: 'center',
    width: '100%',
  },
});
